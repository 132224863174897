angular.module('MyHippoCommons.Directives').directive('basicModal', function () {
    return {
        scope: {
            primaryText: '<',
            primaryAction: '&?',
            secondaryText: '<',
            secondaryAction: '&?',
            forms: '=',
            title: '<',
            canClose: '<',
            primaryDisabled: '<',
            onDontShowAgainClicked: '<',
            dontShowAgain: '<',
        },
        restrict: 'E',
        transclude: true,
        templateUrl: '/modals/basic/basic.html',
        controller: function ($scope, $mdDialog, $element) {
            $scope.primaryAction = $scope.primaryAction || function () {
                $mdDialog.hide();
            };

            $scope.secondaryAction = $scope.secondaryAction || function () {
                $mdDialog.cancel();
            };

            $scope.close = function () {
                $mdDialog.cancel();
            };

            $element.bind('click', function ($event) {
                $event.stopPropagation();
                $event.preventDefault();
            });
        },
    };
});
